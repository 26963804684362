<template>
  <PaymentStatus status="fail" />
</template>

<script>
import PaymentStatus from '@/components/_common/PaymentStatus/PaymentStatus';
export default {
  components: {
    PaymentStatus,
  },
};
</script>

<style lang="scss" scoped></style>
