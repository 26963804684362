<template>
  <button
    :class="[
      'SimpleButton',
      {
        ['SimpleButton_success']: success,
        ['SimpleButton_fail']: fail,
      },
    ]"
    @click="handleClick"
    :disabled="disabled"
  >
    <span class="SimpleButton_text">{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: 'Close',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    success: {
      type: Boolean,
      default: true,
    },
    fail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClick(e) {
      e.preventDefault();
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.SimpleButton {
  width: 120px;
  height: 40px;
  border-radius: 4px;
  &.SimpleButton_success {
    background: #5abc67;
  }
  &.SimpleButton_fail {
    background: #ff556d;
  }
}

.SimpleButton_text {
  font-weight: bold;
  font-size: 12px;
  line-height: 40px;
  text-align: center;
  text-transform: uppercase;
  color: #0f1e30;
}
</style>
