<template>
  <div class="PaymentStatus_container">
    <div class="PaymentStatus_root" :class="`PaymentStatus_root--${status}`">
      <div class="PaymentStatus_wrapper">
        <div class="PaymentStatus_title" :class="`PaymentStatus_title--${status}`">{{ computedTitle }}</div>
        <div class="PaymentStatus_button">
          <SimpleButton :success="status === 'success'" :fail="status === 'fail'" @click="handleClosePaymentStatus" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleButton from '@/components/_common/FormElements/SimpleButton/SimpleButton';

export default {
  components: {
    SimpleButton,
  },
  props: {
    status: {
      // success or fail
      type: String,
      default: 'success',
    },
  },
  computed: {
    computedTitle() {
      return this.status === 'success' ? 'Payment successful' : 'Payment failed';
    },
  },
  methods: {
    handleClosePaymentStatus() {
      const event = new CustomEvent('paymentFinished', { detail: 'makeRedirect' });
      window.parent.document.dispatchEvent(event);
      this.$router.push({ name: 'exchange.wallet.balance' });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_colors.scss';
.PaymentStatus_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.PaymentStatus_root {
  border-radius: 4px;
  border: 1px solid;
  max-width: 300px;
  width: 100%;
  &--success {
    border-color: #5abc67;
    background: rgba(90, 188, 103, 0.1);
  }
  &--fail {
    border-color: #ff556d;
    background: rgba(255, 85, 109, 0.1);
  }
}

.PaymentStatus_wrapper {
  padding: 26px 0 29px;
}

.PaymentStatus_title {
  font-size: 21px;
  text-align: center;
  margin-bottom: 20px;
  &--success {
    color: #5abc67;
  }
  &--fail {
    color: #ff556d;
  }
}
</style>
